@import 'common/theme/responsive.module.scss';

.pricingWrapper {
  display: flex;
  background-color: transparent;
  @include mobile {
    align-items: flex-end;
  }
  .content {
    width: 43rem;
    height: auto;
    display: flex;
    flex-direction: column;
    background-color: var(--theme-background-color-primary);
    position: relative;
    padding: var(--p12) 1.75rem;
    padding-top: 2.5rem;
    overflow: auto;
    @include mobile {
      width: 100%;
      height: auto;
    }
    .newFeature {
      position: absolute;
      top: 1rem;
      left: 0px;
      background-color: #ff6937;
      color: white;
      font-weight: bold;
      padding: 0.3rem;
      @include mobile {
        top: -0.7rem;
      }
      display: none;
    }
    .closeIcon {
      position: absolute;
      top: var(--p12);
      right: var(--p12);
      opacity: 0.5;
      cursor: pointer;
    }
    .imageSection {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;
      .billingAlert {
        display: flex;
        width: 100%;
        height: auto;
        @include mobile{
          display: none;
        }
      }
    }
    .textContent {
      display: flex;
      margin-bottom: 3rem;
      margin-top: 3.125rem;
      flex-direction: column;
      .img{
        height: 6rem;
        display: flex;
        align-self: center;
        @include mobile {
          width: 85%;
          max-width: 31.188rem;
          height: auto;
        }
      }
      @include mobile {
        margin-left: 1.938rem;
        margin-bottom: 1.5rem;
        margin-right: 1.938rem;
        margin-top: 2rem;
      }
      .header {
        display: flex;
        margin-bottom: var(--p24);
        font-weight: 500;
        justify-content: center;
      }
      .p {
        margin-bottom: 2.063rem;
        text-align: justify;
        line-height: var(--p20);
        color: var(--theme-text-color-tertiary);
        
      }
      .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        .stop {
          display: flex;
          background-color: var(--color-white);
        }
      }
      .link {
        display: flex;
        color: #FF6937;
        text-decoration: underline;
        font-size: var(--font-size-base);
        text-align: center;
        justify-content: center;
      }
    }
    .close {
      position: absolute;
      right: 0px;
      top: 0px;
      opacity: var(--opacity-1);
      &:hover {
        opacity: var(--opacity-3);
        cursor: pointer;
      }
    }
  }
}
